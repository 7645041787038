/** @format */

import logoDark from "@/images/logo.png";
import vvSistemLogo from "@/images/logo4.png";
import slideOneImage1 from "@/images/slider/home2.jpg";
import slideOneImage2 from "@/images/slider/home4.jpg";
import serviceImage1 from "@/images/services/job1.jpg";
import serviceImage2 from "@/images/services/job2.jpg";
import serviceImage3 from "@/images/services/job3.jpg";
// import funfactShape from "@/images/shape/about-shape.png";
import galleryOne3 from "@/images/gallery/slika3.jpg";
import galleryOne4 from "@/images/gallery/slika4.jpg";
import galleryOne5 from "@/images/gallery/slika5.jpg";
import galleryOne6 from "@/images/gallery/slika6.jpg";
import testimonial1 from "@/images/testimonial/avatarGirl3.png";
import testimonial2 from "@/images/testimonial/avatarLaki.jpeg";
import testimonial3 from "@/images/testimonial/avatarJoca.jpeg";
import testimonial4 from "@/images/testimonial/avatarGirl2.jpeg";
import blog1 from "@/images/blog/blog7.jpeg";
import blog2 from "@/images/blog/portable.jpeg";
import blog3 from "@/images/blog/blog2.jpeg";
import team1 from "@/images/team/vace1.jpeg";
import team2 from "@/images/team/worker1.jpeg";
import team3 from "@/images/team/worker2.jpeg";
import team4 from "@/images/team/worker5.jpeg";
import midea1 from "@/images/klime/midea1.jpg";
import midea2 from "@/images/klime/midea2.jpg";
import midea3 from "@/images/klime/midea3.jpg";
import vivax1 from "@/images/klime/vivax1.jpg";
import vivax2 from "@/images/klime/vivax2.jpg";
import vivax3 from "@/images/klime/vivax3.jpg";

export const Logo = {
  dark: vvSistemLogo,
};

export const NavLinksData = [
  {
    name: "Početna",
    url: "/",
    // subItems: [
    //   {
    //     name: "Home 01",
    //     url: "/"
    //   },
    //   {
    //     name: "Home 02",
    //     url: "/index-2"
    //   }
    // ]
  },
  {
    name: "O nama",
    url: "/o-nama",
  },
  {
    name: "Usluge",
    url: "/usluge",
  },
  // {
  //   name: "Usluge",
  //   url: "/service",
  //   subItems: [
  //     {
  //       name: "Instalacija",
  //       url: "/service"
  //     },
  //     {
  //       name: "Servisiranje",
  //       url: "/service-details"
  //     },
  //     {
  //       name: "Prodaja",
  //       url: "/service-details"
  //     },

  //   ]
  // },
  // {
  //   name: "Blog",
  //   url: "/blog",
  //   subItems: [
  //     {
  //       name: "Blog Page",
  //       url: "/blog"
  //     },
  //     {
  //       name: "Blog Details",
  //       url: "/blog-details"
  //     }
  //   ]
  // },
  {
    name: "Cenovnik",
    url: "/cenovnik",
    subItems: [
      {
        name: "Servisiranje",
        url: "/cenovnik",
      },
      {
        name: "Prodaja",
        url: "/prodaja-klima",
      },
    ],
  },
  {
    name: "Kontakt",
    url: "/kontakt",
  },
];

export const TopbarInfos = [
  {
    icon: "icon-placeholder",
    tagLine: "Beograd",
    // text: "Beograd",
    url: "#",
  },
  {
    icon: "icon-contact",
    tagLine: "E-mail",
    text: "klimaservisvv@gmail.com ",
    url: "mailto:vvsistem@gmail.com",
  },
  {
    icon: "icon-phone-call",
    tagLine: "Pozovite",
    text: "060/025 60 31",
    url: "tel:+381600256031",
  },
];

export const SlideOneData = [
  {
    backgroundImage: slideOneImage1,
    title: "Specijalista za klima uređaje",
    text: "Izaberite najbolju kompaniju",
    button: "BESPLATNA PONUDA",
    url: "/contact",
  },
  {
    backgroundImage: slideOneImage2,
    title: "Instalacija i servisiranje",
    text: "Pouzdani klima uređaji",
    button: "BESPLATNA PONUDA",
    url: "/contact",
  },
  // {
  //   backgroundImage: slideOneImage3,
  //   title: "Heating and Air Conditioning Specialist",
  //   text: "Choose the Best Company",
  //   button: "GET A FREE QUOTE",
  //   url: "/contact"
  // }
];

export const FeatureOneData = [
  {
    icon: "icon-wallet",
    title: "Bez plaćanja unapred",
  },
  {
    icon: "icon-diploma",
    title: "Zagarantovano zadovoljstvo",
  },
  {
    icon: "icon-big_clock",
    title: "Brza usluga",
  },
  {
    icon: "icon-handshake",
    title: "Pravo obećanje",
  },
  {
    icon: "icon-mechanic",
    title: "8 godina iskustva",
  },
];

export const AboutOneData = {
  title: "Firma za instalaciju i servisiranje klima uređaja",
  tagLine: "Dobrodošli na VV Sistem",
  content:
    "VV Sistem je vaš najbolji prijatelj kada se radi o klima uređajima. Postojimo više od 8 godina, a broj naših zadovoljnih korisnika je preko 10.000. Tačnost, preciznost i pedantnost je ono što našu firmu opisuje.",
  button: {
    url: "/about",
    label: "Saznaj više",
  },
};

export const ServiceOneData = {
  title: "Naše usluge",
  posts: [
    {
      image: serviceImage1,
      icon: "icon-snow",
      title: "Redovan servis",
      text: "Kvalitet vazduha kao najbitniji faktor korisniku koji boravi u prostoriji zavisi od kvaliteta i čistoće filtera u unutrašnjoj jedinici klima uređaja.",
      button: {
        label: "SAZNAJ VIŠE",
        url: "/service-details",
      },
    },
    {
      image: serviceImage2,
      icon: "icon-fire",
      title: "Instalacija novog uređaja",
      text: "Danas tržište nudi ogroman broj uređaja sa različitim specifičnostima. Posavetujte se sa nama u vezi odabira i instalacije novog uređaja.",
      button: {
        label: "SAZNAJ VIŠE",
        url: "/service-details",
      },
    },
    {
      image: serviceImage3,
      icon: "icon-ac",
      title: "Ostale usluge",
      text: "Pored instalacije i servisiranja standardnih klima uređaja, VV Sistem nudi i usluge  koje se odnose na split siteme klima uređaja.",
      button: {
        label: "SAZNAJ VIŠE",
        url: "/service-details",
      },
    },
  ],
};

export const CallToActionOneData = {
  title: "Bez prekovremenih troškova, 7 dana u nedelji",
  button: {
    url: "/contact",
    label: "BESPLATNA PONUDA",
  },
};

export const CallToActionTwoData = {
  left: {
    text: "Air Conditioning Services",
    title: "Cool it down",
  },
  right: {
    text: "Heating Services",
    title: "Heat it up",
  },
};

export const FunfactOneData = {
  // shapeImage: funfactShape,
  title: "Brojevi govore",
  posts: [
    {
      count: 8870,
      text: "Popravka klima uređaja",
    },
    {
      count: 4760,
      text: "Instalacija klima uređaja",
    },
    {
      count: 1032,
      text: "Besplatnih konsultacija",
    },
  ],
};

export const GalleryOneData = [
  {
    title: "Precizno merenje",
    image: galleryOne3,
    url: "#",
  },
  {
    title: "Burgijanje bez oštećenja",
    image: galleryOne4,
    url: "#",
  },
  {
    title: "Postavljanje spoljne jedinice",
    image: galleryOne5,
    url: "#",
  },
  {
    title: "Postavljanje unutrašnje jedinice",
    image: galleryOne6,
    url: "#",
  },
];

export const TestimonialOneData = {
  title: "Testimonials",
  posts: [
    {
      image: testimonial1,
      content:
        "Vrhunska usluga, konkurentna cena i odlična korisnička podrška. Potpuno osveženje na tržištu.",
      name: "Marija Tomić",
      designation: "Lekar opšte prakse",
    },
    {
      image: testimonial2,
      content:
        "Maksimalno pedantno i brzo. Jedna ogromna preporuka za VV Sistem!",
      name: "Lazar Stanković",
      designation: "Video editor",
    },
    {
      image: testimonial3,
      content:
        "Ekipa je u roku od 2 sata instalirala novi uređaj. Oduševljen sam.",
      name: "Jovan Stanić",
      designation: "Direktor",
    },
    {
      image: testimonial4,
      content:
        "Došli su na vreme, servisirali klimu profesionalno i brzo. Nisu ostavili nikakav nered iza sebe. Svaka preporuka za ovaj servis!",
      name: "Ema Tomić",
      designation: "CEO",
    },
  ],
};

export const FooterBottomData = {
  social: [
    {
      icon: "fa fa-instagram",
      url: "https://tinyurl.com/3zsrchy4",
    },
    {
      icon: "fa fa-facebook-f",
      url: "#",
    },
    {
      icon: "fa fa-youtube-play",
      url: "#",
    },
  ],
};

export const FooterInfo = [
  {
    icon: "icon-placeholder",
    tagLine: "Beograd",
    // text: "Bulevar JNA 22, Beograd",
    url: "#",
  },
  {
    icon: "icon-contact",
    tagLine: "Email",
    text: "klimaservisvv@gmail.com ",
    url: "mailto:vvsistem@gmail.com",
  },
  {
    icon: "icon-clock",
    tagLine: "Radno Vreme",
    text: " Pon - Ned: 08:00 - 17:00",
    url: "#",
  },
];

export const FooterWidgets = {
  about: {
    title: "O VV Sistemu",
    text: "U VV Sistemu, mi ne pružamo samo usluge klimatizacije za stambene i poslovne prostore, mi olakšavamo život ljudima.",
    tagLine: "Kontaktiraj Nas",
    phone: "060/025 60 31",
  },
};

export const BlogOneData = {
  title: "Najnovije vesti",
  posts: [
    {
      image: blog1,
      date: "23 Maj",
      author: "VV Sistem",
      commentCount: 3,
      title: "Optimalna temperatura",
      url: "/blog-details",
      text: "Optimalna temperatura zavisi od spoljašnje temperature. Preporuka je da leti ne bude razlika u temperaturi veća od 10° između spoljašnje i unutrašnje temperature.",
    },
    {
      image: blog2,
      date: "07 Jun",
      author: "VV Sistem",
      commentCount: 3,
      title: "Pokretni klima uređaji",
      url: "/blog-details",
      text: "Pokretni klima uređaji su lako prenosivi i ne zahtevaju komplikovanu instalaciju. Karakteriše ih dug životni vek i niža cena od standardnih. Namenjeni su za manje površine.",
    },
    {
      image: blog3,
      date: "22 Nov",
      author: "VV Sistem",
      commentCount: 3,
      title: "Zima sa klima uređajem",
      url: "/blog-details",
      text: "Standardni klima uređaji mogu efikasno da greju samo dok je napolju temperatura iznad -5°. U ponudi se nalaze i uređaji sa takozvanom inverter tehnologijom koji mogu da greju do -18°.",
    },
  ],
};

export const VideoOneData = {
  title: "Kako mi to radimo",
  id: "nMDV58OjAbg",
};

export const AboutTwoData = {
  caption: " 8 <span>godina iskustva</span> ",
  blockTitle: {
    title: "Mi smo firma od poverenja kada su u pitanju klima uređaji",
    tagline: "Zašto odabrati nas",
  },
  lists: [
    {
      text: " Garancija na sve usluge",
    },
    {
      text: " Pedantnost pri obavljanju usluga i bez ostavljanja tragova",
    },
    {
      text: " Unapred poznata cena i bez naknadnih troškova",
    },
    {
      text: " 24 / 7 dostupnost za sve hitne usluge",
    },
    {
      text: " Urađeno po dogovorenom ili je usluga besplatna",
    },
    {
      text: " Brza i laka komunikacija",
    },
  ],
  offer: {
    tagline: "10% sniženja",
    text: "na sve naše usluge za penzionere.",
  },
};

export const TeamOneData = {
  title: "Upoznajte tim",
  posts: [
    {
      image: team1,
      name: "Vlada Velinović",
      designation: "Osnivač i glavni serviser",
      social: [
        {
          icon: "fa-facebook",
          url: "#",
        },
        {
          icon: "fa-twitter",
          url: "#",
        },
        {
          icon: "fa-youtube-play",
          url: "#",
        },
      ],
    },
    {
      image: team2,
      name: "Stefan Velinović",
      designation: "Serviser",
      social: [
        {
          icon: "fa-facebook",
          url: "#",
        },
        {
          icon: "fa-twitter",
          url: "#",
        },
        {
          icon: "fa-youtube-play",
          url: "#",
        },
      ],
    },
    {
      image: team3,
      name: "Dragan Gaga",
      designation: "Glavni montažer",
      social: [
        {
          icon: "fa-facebook",
          url: "#",
        },
        {
          icon: "fa-twitter",
          url: "#",
        },
        {
          icon: "fa-youtube-play",
          url: "#",
        },
      ],
    },
    {
      image: team4,
      name: "Stefan Cece",
      designation: "Pomoćni montažer",
      social: [
        {
          icon: "fa-facebook",
          url: "#",
        },
        {
          icon: "fa-twitter",
          url: "#",
        },
        {
          icon: "fa-youtube-play",
          url: "#",
        },
      ],
    },
  ],
};

export const BlogTwoData = {
  posts: [
    {
      image: midea1,
      author: "admin",
      commentCount: 3,
      title: "MIDEA MSAG-12HRN1",
      url: "/blog-details",
      text: "420€",
    },
    {
      image: midea2,
      author: "admin",
      commentCount: 3,
      title: "MIDEA AG12NXD1",
      url: "/blog-details",
      text: "600€",
    },
    {
      image: midea3,
      author: "admin",
      commentCount: 3,
      title: "MIDEA MSFAAU-12HRFN8 ",
      url: "/blog-details",
      text: "660€",
    },
    {
      image: vivax2,
      author: "admin",
      commentCount: 3,
      title: "VIVAX ACP-12CH35AEFIs",
      url: "/blog-details",
      text: "390€",
    },
    {
      image: vivax2,
      author: "admin",
      commentCount: 3,
      title: "VIVAX ACP-12CH35AEQIs",
      url: "/blog-details",
      text: "400€",
    },
    {
      image: vivax3,
      author: "admin",
      commentCount: 3,
      title: "VIVAX ACP-12CH35AEMIs (wifi)",
      url: "/blog-details",
      text: "420€",
    },
  ],
};

export const AboutFourData = {
  blockTitle: {
    title: "Zaradili smo poverenje kod više od <span>10000</span> klijenata",
    text1:
      "Od 2014. godine beležimo stalni rast zadovoljnih klijenata kako na području Beograda tako i na području cele Srbije. Postali smo ovlašćeni servis LG, Midea, Gree, Vox, Beko i Samsung klima uređaja.",
    text2:
      "Edukacijom servisera na stručnim seminarima obezbedili smo da na profesionalnom nivou odgovorimo svim vašim željama i zahtevima uz maksimalnu pedantnost.",
  },
};

export const ServiceSidebarListData = [
  {
    icon: "icon-snow",
    title: "Servis klima uređaja",
    list: [
      {
        label: "Održavanje klima uređaja",
        url: "#",
      },
      {
        label: "Popravka klima uređaja",
        url: "#",
      },
      {
        label: "Dubinsko čišćenje klima uređaja",
        url: "#",
      },
      {
        label: "Popravka elektonskih komponenti",
        url: "#",
      },
      {
        label: "Montaža i demontaža",
        url: "#",
      },
    ],
  },
  {
    icon: "icon-ac",
    title: "Prodaja i otkup uređaja",
    list: [
      {
        label: "Prodaja klima uređaja",
        url: "#",
      },
      {
        label: "Prodaja inverter klima uređaja",
        url: "#",
      },
      {
        label: "Prodaja pokretnih klima uređaja",
        url: "#",
      },
      {
        label: "Otkup starih klima uređaja",
        url: "#",
      },
    ],
  },
];

export const ServiceSidebarOtherData = [
  {
    icon: "icon-ac",
    title: "Other Services",
    content: {
      icon: "icon-support",
      title: "<span>Kontaktirajte nas </span>060/025 60 31",
    },
  },
  {
    icon: "icon-building",
    title: "Commercial Services",
    content: {
      icon: "icon-building",
      title: "<span>Kontaktirajte nas </span>060/025 60 31",
    },
  },
];
